#poster-container{
  z-index: 4;
  width: 100vw;
  height: 100%;

  .reminder{
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 64px;
    bottom: 34px;
    color: white;
    font-size: 14px;

    img{
      margin-top: 10px;
      width:19px;
      height: 33px;
    }
  }

  .bottomContainer{
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 345px;
    margin: 55px auto 0 auto;

    .textAndQrcodeContainer{
      z-index: 5;
      display: flex;
      align-items: flex-end;
    }

    .textContainer{
      display: flex;
      flex-direction: column;
      text-align:left;
      z-index:5;
      color: white;
      margin-left: 10px;
    }
    .qrCode{
      z-index:5;
    }
    .logo{
      z-index:5;
      height: 27px;
    }
  }
}

.stageDiv{
  height: 100%;
}