.previous-frame-container {
  position: relative;
  width: 331px;
  height: 526px;
  background: url("./assets/frame.png") no-repeat center;
  background-size: 100% 100%;
  display:flex;
  flex-direction:column;
  align-items: center;
  margin-bottom: 40px;

  .nft-image-container{
    position: relative;
    width: 315.18px;
    height: 315.18px;
    display: flex;
    align-items:center;
    justify-content:center;
    background: rgba(225, 225, 225, 0.01);
    margin-top:44px;
  }

  .nft-image{
    position: relative;
    width: 300.5px;
    height:300.5px;
  }

  .nft-header {
    position: relative;
    text-align: start;
    display:flex;
    width: 331px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    margin-top:22px;
    padding-left: 36px;
  }

  .author-container {
    position: relative;
    display: flex;
    flex-direction:row;
    align-items:center;
    margin-top: 10px;
    width:100%;

    .avatar {
      position: relative;
      height:32px;
      width:32px;
      margin-right: 10px;
      margin-left: 17px;
    }

    .author-name {
      position: relative;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.01px;
      color: #FFFFFF;
    }
  }

  .collect-btn{
    position: relative;
    width: 298px;
    height: 40px;
    background: #281EC8;
    border-radius: 28px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 13px;
  }

  .disabled-btn{
    position: relative;
    width: 298px;
    height: 40px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 28px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 13px;
  }
}