@use "../../variables" as v;

#list-container{
  height: 100vh;
  display: flex;
  justify-content: center;

  #content-container{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    margin-top: 60px;
    height: 83vh;
    align-content: flex-start;
    width: 380px;
  }

  .duty-container {
    position: absolute;
    bottom: 22px;
  }
}