@use "../../variables" as v;

#home-container{
  min-height: 100vh;
  background-size: cover;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: v.$theme-gold;

  #light-container{
    position: absolute;
    top: -80px;
  }

  .nft-id{
    font-size: 14px;
  }

  // #swiper-container{
  //   width: 100%;
  //   height: 250px;
  // }

  #platform{
    width: 100vw;
    height: 115px;
    position: absolute;
    bottom: 156px;

    img{
      width: 100%;
      height: 100%;
    }
  }

  #retrieve-button{
    //position: absolute;
    //bottom: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 298px;
    height: 40px;
    background: #281EC8;
    border-radius: 28px;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    box-shadow: 0px 10px 11px rgba(38, 6, 2, 0.47);
    z-index: 5;
    margin-top: 455px;
    margin-bottom: 50px;
  }

  .duty-container {
    position: absolute;
    bottom: 10px;
  }
}

@media screen and (min-height: 600px) {
  #home-container {
    min-height: 80vh;
  }
}