.info-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-blend-mode: overlay;
  // background: linear-gradient(
  //                 109.37deg,
  //                 rgba(255, 255, 255, 0.13) 12.9%,
  //                 rgba(255, 255, 255, 0.06) 77.62%
  // );
  //box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.28);
  backdrop-filter: blur(20px);

  // border: 1px solid;
  border-image-slice: 1;
  // border-image-source: linear-gradient(
  //                 107.13deg,
  //                 rgba(176, 233, 246, 0.28) 11.28%,
  //                 rgba(255, 148, 201, 0) 38.99%,
  //                 rgba(193, 73, 133, 0) 80.03%,
  //                 rgba(175, 233, 246, 0.48) 92.71%
  // );

  color: white;

  width: 343px;
  height: 170px;

  .glass-background {
    background-size: contain;
    padding: 11px 12px 1px 15px;
    background-origin: padding-box;
    box-sizing: border-box;

    background-image: url(../../assets/info-background.png);
    width: 100%;
    height: 100%;

    .detail-container {
      display: flex;
      flex-direction: column;

      .detail-pair {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .detail-key {
          color: #bcbcbc;
        }

        .detail-value {
          color: white;
          width: 220px;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}