@use "../../variables" as v;

.login {
  position: relative;
  height: 100vh;
  padding: 0 28px;
  background-size: cover;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;


  .title-container{
    width: 118px;
    height: 85px;
    padding-top: 69px;

    padding-bottom: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    img{
      height: 68px;
      width: 123px;
    }
  }

  .input-container{
    width: 335px;
    height: 50px;
    display: flex;
    justify-content: space-between; //验证码在右边———
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 0.5px solid rgba(199, 199, 199, 0.2);
    ::-webkit-input-placeholder{
      color: #C7C7C7;
    }

    button{
      font-size: 14px;
    }

    input{
      border:none;
      padding: 0 0 0 0;
      // border-radius: 30px;   没有边框也不需要改属性
      font-size: 14px;
      outline: none;
    }

    .phone{
      width: 100%;
      height: 100%;
    }

    .input-wrapper .code{
      width: 180px;
      height:100%;
      margin-right: 18px;
    }

    .code-button{
      background-color:white;
      width: 100px;
      border:none;
      padding: 0;
      text-align: right;
    }

    .code-button:disabled{
      color: white;
    }


    .full-length-button{
      width: 100%;
      height: 100%;
      border-radius: 30px;
      outline: none;
      box-shadow: none;
      font-weight: 500;
      border: none;
      // background: rgba(124, 124, 124, 1);
      // color: white;
    }

    // .checked {
    //   background: v.$theme-red;
    //   color: white;
    // }
  }

  .agreement-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    .agreement-label{
      display: flex;
      margin-left: 10px;
     
    }

  }

  .theme-color{
    font-size: 14px;
  }
}







