.nft-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-image: url("../../assets/back-light.png");
  background-size: cover;
  background-position: center;
  width: 100%;
}

  .card-container {
    margin: 15px 14px;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:2;

    img {
      //border-radius: 12px;
    }

    .content-container {
      width: 92px;
      height: 92px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nft-name{
    color: white;
    font-size: 12px;
    font-weight: 400;
    width: 162px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto;
  }
