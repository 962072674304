#about-container{
  position: relative;
  height: 100vh;
  z-index: 4;
  color:white;

  #about-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    text-align: left;
    padding:0 43px ;
    font-size: 12px;
    font-weight: 400;
    margin-top: 90px;
    line-height: 30px;

    #about-title{
      font-size: 36px;
      font-weight: 700;
    }

    .lineBetween{
      margin: 30px 0px 30px 0px;
    }
  }
}