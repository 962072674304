.backgroundContainer{
  height: 100%;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;

  .text{
    position: absolute;
    margin-top: -100px;
    height: 18px;
    width: 221px;
  }

  .ornament1{
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
  .ornament2{
    width: 75px;
    height: 107px;
    position: absolute;
    bottom: 85px;
    left: 0;
  }
  .ornament3{
    width: 54px;
    height: 48px;
    position: absolute;
    right: 0;
    top:515px;
  }
  .ornament4{
    width: 232px;
    height: 185px;
    position: absolute;
    margin-top: 41px;
  }

  .ornament5{
    width: 54px;
    height: 48px;
    position: absolute;
    top:530px;
    right:-21px;
  }
}