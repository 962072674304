.previous-collection-container {
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 145px;

  .title {
    position: relative;
    background: url("./assets/title.png") no-repeat center;
    background-size: 100% 100%;
    width: 221px;
    height: 33px;
    margin-bottom: 20px;
  }
}
